import React, { useEffect } from 'react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import bgImg from '../../assets/testimonials-bg.jpg'
import { FaQuoteLeft } from "react-icons/fa6";
import { FaQuoteRight } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';
// import img1 from '../../assets/testimonial-img.png'
import img1 from '../../assets/testimonial-img-new.png'

const Testimonial = () => {
  useEffect(() => {
    AOS.init();
}, []);
  return (
    <div  className='testimonials overflow-hidden' >
      <div className='container'>
        
      <div className=''>

<Swiper
  spaceBetween={30}
  centeredSlidms={true}
  autoplay={{
    delay: 2500,
    disableOnInteraction: false,
  }}
  pagination={{
    clickable: true,
  }}
  // navigation={true}
  modules={[Autoplay, Pagination, Navigation]}
  className="mySwiper"
>
  <SwiperSlide>
    <div className='py-4 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'>Mia Otto Nilssons Byggnads AB</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para '> <span className='quote-icon-left'><FaQuoteLeft className='quote-icons'/></span>Omni är ett företag som verkligen gör sitt yttersta för att kunden ska bli nöjd. Förstklassig service och snabb återkoppling gör att vi gärna rekommenderar Omni till andra<span className='quote-icon-right'><FaQuoteRight className='quote-icons'/></span></p>
    
    </div>
  </SwiperSlide>
 
  <SwiperSlide>
    <div className='py-4 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'>Ronneby Pastorat</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para'> <span className='quote-icon-left'><FaQuoteLeft className="quote-icons"/></span>Kraftig besparing & support i världsklass<span className='quote-icon-right'><FaQuoteRight className='quote-icons'/></span></p>
    
    </div>
  </SwiperSlide>
 
  <SwiperSlide>
    <div className='py-4 mx-auto flex justify-center items-center flex-col'>
      <img src={img1 } alt="" className='testimonial-img ' />
      <h3 className='testimonial-item-h3'> Fredrik Autovendo Falkenberg</h3>
      {/* <h4 className='testimonial-item-h4'>Ceo & Founder</h4> */}
      <p className='testimonial-item-para'> <span className='quote-icon-left'><FaQuoteLeft className='quote-icons'/></span>Bra support & smarta flexibla kundanpassade funktioner <span className='quote-icon-right'><FaQuoteRight className='quote-icons'/></span></p>
    
    </div>
  </SwiperSlide>
 
</Swiper>
</div>
      </div>
      
    </div>
  )
}

export default Testimonial








