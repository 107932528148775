import React, { useRef, useState } from 'react'
// import logo from '../assets/omni-telecom-log.png'
import logo from '../assets/logo-omni-photo.png';
import { NavLink } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';


const Footer = () => {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const menuRef = useRef(null)

    const toggleMenu = () => {
        setShow(!show)
    }

    const handleClose = () => {
        setShow(false)
    }

    const searchOpen = () => {
        setOpen(true)
    }

    const searchClose = () => {
        setOpen(false)
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div  className='main_footer' >
            <div class="pg-footer" >   
          
                <div class="footer" >
                    <div className='w-101 overflow-hidden footer-wave-parent '>
                    {/* <svg 
  className="new-footer-svg"
  preserveAspectRatio="none" 
  xmlns="http://www.w3.org/2000/svg" 
  viewBox="0 0 1440 320"
  style={{ display: 'block', width: '100%', height: 'auto' }}
>
  <path 
    fill="#1e2025"  
    fillOpacity="1" 
    d="M0,224L60,202.7C120,181,240,139,360,144C480,149,600,203,720,240C840,277,960,299,1080,277.3C1200,256,1320,192,1380,160L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
  />
</svg>     */}

                        <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                            <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                        </svg>
                    </div>
                    <div className='container'>
                        <div className="d-md-flex justify-content-md-between mt-5" >
                            <div className="" >
                                <Link to='/' onClick={() => { handleClose(); scrollToTop() }}> <img src={logo} className="footer-logo" /></Link>
                                <div className='footer-contact-details mt-4' >

                                    <p><Link className="align-items-center arrow d-flex gap-2" to='mailto:kontakt@omnitelecom.se'> <MdEmail /> kontakt@omnitelecom.se</Link> </p>
                                    <p className='align-items-center d-flex gap-2'><FaPhone className="arrow" /> +46 40 93 31 00</p>
                                </div>
                                <div className="social-links my-3" >

                                    <Link to='https://www.linkedin.com/company/omni-telecom/' target='_blank' className="social-icon" >
                                        <FaLinkedinIn />
                                    </Link>
                                </div>
                            </div>
                            <div className=''>
                                <div>
                                    <h3 className='' >Snabb länk</h3>
                                    <ul className="footer-list" >
                                        <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/' className='align-items-center d-flex gap-1 li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Hem</Link></li>
                                        <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/aboutUs' className='align-items-center d-flex gap-1 li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Om oss</Link></li>
                                        <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/contactUs' className='align-items-center d-flex gap-1 li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Kontakta oss</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="" >
                                <div className=''>
                                    <h3 className='' >Adress</h3>
                                    <ul className="footer-list" >
                                        <li className='li_footer'>Omni Telecom AB</li>
                                        <li className='li_footer'>Södergatan 3</li>
                                        <li className='li_footer'>211 34 Malmö</li>
                                        <li className='li_footer'>Org nr: 559265-0278</li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <div className="copyright text-center py-2" >
                            <p>©2022 Omni Telecom</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer
